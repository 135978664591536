<template>
   
</template>

<script>
export default {
    name: "headerComponent",
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
