import './bootstrap';
import { createApp, markRaw } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router';
import { createPinia } from 'pinia';
import { vMaska } from 'maska';
import moment from 'moment';
import "vue-toastification/dist/index.css";
import '@/index.css';

import App from '../App.vue';
import { useAuthStore } from "@/Stores/Auth.js";

import { createI18n } from 'vue-i18n';
import en from '../lang/en.json';
import pt from '../lang/pt_BR.json';
import es from '../lang/es.json';

const messages = {
  en,
  pt,
  es,
};

const i18n = createI18n({
  legacy: false, // Usando a nova API (Vue 3)
  locale: 'pt',  // Idioma padrão
  fallbackLocale: 'en', // Idioma de fallback
  messages, // Suas traduções
});

/**
 * APP
 */
const app = createApp(App);


/**
 * Axios
 */
import axios from 'axios'
import VueAxios from 'vue-axios'

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'

import VueFullscreen from 'vue-fullscreen'
app.use(VueFullscreen)

/**
 * Toast
 */
import Toast from "vue-toastification";
const optionsToast = { }
app.use(Toast, optionsToast)

/**
 * Router
 */
import router from '../Router/index.js';
import { useSettingStore } from "@/Stores/SettingStore.js";
app.use(router);

/// Directive
app.directive("maska", vMaska)

/**
 * PINIA
 * @type {Pinia}
 */
const pinia = createPinia()
pinia.use(({ store }) => { store.router = markRaw(router) })
app.use(pinia);

/**
 * i18n (Internacionalização)
 */
app.use(i18n);

/**
 * Carregamento de Configurações e Autenticação
 */
(async () => {
  const setting = useSettingStore();
  try {
    const settingData = await setting.getSetting();
    setting.setSetting(settingData);
  } catch (e) {
  }
})()

if (localStorage.getItem('token')) {
  (async () => {
    const auth = useAuthStore();
    try {
      auth.setIsAuth(true);
      const user = await auth.checkToken();
      if (user !== undefined) {
        auth.setUser(user);
      }
    } catch (e) {
      auth.setIsAuth(false);
    }
  })()
}

app.mount('#app');
