<template>
  <div class="w-full max-w-xl mx-auto">
    <div class="w-full" style="background-image: url('/storage/rox/style_1_topbg_yd.avif'); background-size: cover; background-position: center;">
      <div class="w-full flex px-2 py-4 justify-between">
        <div class="flex gap-2">
          <img class="w-[60px] h-[60px] rounded-full" :src="`/storage/rox/img_txn23.png`" alt="minha foto"/>

          <div>
            <p class="pl-2">Conta: <span class="text-white">{{ userName }}</span></p>
            <div class="pl-2 flex items-center">
              <p>ID: <span class="text-white">{{ idUser }}</span> <i class="fa-regular fa-copy text-[var(--ci-primary-opacity-color)] cursor-pointer ml-2"></i></p>
              <WalletBalance style="border:none !important; transform: scale(1.2); margin-left: 10px;"/>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex p-4 justify-between items-center">
        <div @click.prevent="toggleModalDeposit" class="flex flex-col items-center justify-center gap-1 px-2">
          <i class="fa-solid fa-money-bill-transfer block text-2xl text-[var(--ci-primary-opacity-color)]"></i>
          <p class="block text-white text-sm">Depositar</p>
        </div>

        <div @click="$router.push('/profile/withdraw')" class="flex flex-col items-center justify-center gap-1 px-2">
          <i class="fa-solid fa-sack-dollar block text-2xl text-[var(--ci-primary-opacity-color)]"></i>
          <p class="block text-white text-sm">Sacar</p>
        </div>

        <div @click="$router.push('/profile/transactions')" class="flex flex-col items-center justify-center gap-1 px-2">
          <i class="fa-solid fa-cash-register block text-2xl text-[var(--ci-primary-opacity-color)]"></i>
          <p class="block text-white text-sm">Transações</p>
        </div>
      </div>
    </div>

    <img @click="$router.push('/vip')" class="w-full h-auto rounded-lg mb-4" :src="`/storage/rox/vip1.png`" alt="minha foto"/>

    <div id="modalElDeposit" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full overflow-x-hidden overflow-y-auto h-screen">
      <div class="relative w-full max-w-2xl mx-auto my-8 rounded-lg shadow" style="background-color: var(--ci-primary-color);">
        <div class="flex flex-col px-6 pb-8 my-auto">
          <div class="flex justify-center items-center modal-header mb-6 mt-6">
            <div>
              <h1 class="font-bold text-xl text-center" style="var(--ci-gray-light)">Depósito</h1>
            </div>
          </div>

          <DepositWidget v-bind="$attrs" :close-modal="toggleModalDeposit" v-if="modalDeposit"/>
        </div>
      </div>
    </div>

    <div class="mx-2 my-2 flex flex-col p-2 rounded-lg bg-[var(--ci-primary-color)]">
      <div @click="$router.push('/home/agents')" class="flex w-full justify-between items-center text-white border-[var(--ci-secundary-color)] p-1 pt-2 cursor-pointer mb-2">
        <div><p class="text-sm flex gap-3 items-center"><i class="fa-light fa-bullhorn text-xl text-[var(--ci-primary-opacity-color)]"></i> Agente</p></div>
        <i class="fa-solid fa-angle-right text-xl" style="color: #313843;"></i>
      </div>

      <div @click="$router.push('/home/support')" class="flex w-full justify-between items-center text-white border-[var(--ci-secundary-color)] p-1 pt-2 cursor-pointer mb-2">
        <div><p class="text-sm flex gap-3 items-center"><i class="fa-light fa-headset text-xl text-[var(--ci-primary-opacity-color)]"></i> Suporte</p></div>
        <i class="fa-solid fa-angle-right text-xl" style="color: #313843;"></i>
      </div>

      <div @click="$router.push('/home/events')" class="flex w-full justify-between items-center text-white border-[var(--ci-secundary-color)] p-1 pt-2 cursor-pointer mb-2">
        <div><p class="text-sm flex gap-3 items-center"><i class="fa-light fa-globe text-xl text-[var(--ci-primary-opacity-color)]"></i> Eventos</p></div>
        <i class="fa-solid fa-angle-right text-xl" style="color: #313843;"></i>
      </div>

      <!-- Botão Música -->
      <div @click="toggleMusicModal" class="flex w-full justify-between items-center text-white border-[var(--ci-secundary-color)] p-1 pt-2 cursor-pointer mb-2">
        <div><p class="text-sm flex gap-3 items-center"><i class="fa-light fa-list-music text-xl text-[var(--ci-primary-opacity-color)]"></i> Música</p></div>
        <i class="fa-solid fa-angle-right text-xl" style="color: #313843;"></i>
      </div>


      <div @click="logoutAccount" class="flex w-full justify-between items-center text-white p-1 pt-2 cursor-pointer">
        <div><p class="text-sm flex gap-3 items-center"><i class="fa-light fa-arrow-right-from-bracket text-xl text-[var(--ci-primary-opacity-color)]"></i> Sair</p></div>
        <i class="fa-solid fa-angle-right text-xl" style="color: #313843;"></i>
      </div>

      <!-- Modal de Música -->
     <MusicModal v-if="showMusicModal" @close="toggleMusicModal" :musicSelector="showMusicModal"/>
    
    </div>
  </div>

  <BottomNavComponent/>
</template>

<script>
import BottomNavComponent from "@/Components/Nav/BottomNavComponent.vue";
import WalletBalance from "@/Components/UI/WalletBalance.vue";
import DepositWidget from "@/Components/Widgets/DepositWidget.vue";
import WithdrawWidget from "@/Components/Widgets/WithdrawWidget.vue";
import HttpApi from "@/Services/HttpApi.js"; 
import { useAuthStore } from "@/Stores/Auth.js";
import { useToast } from "vue-toastification"; // Ajuste para o caminho correto
import {useSettingStore} from "@/Stores/SettingStore.js";
import MusicModal from "@/Components/Nav/MusicModal.vue"; // Importando o modal de música

export default {
  inheritAttrs: false,
  components: { BottomNavComponent, WalletBalance, DepositWidget, WithdrawWidget, MusicModal },
  data() {
    return {
      idUser: '',
      userName: '',
      modalDeposit: null,
      isLoadingWallet: true,
      wallet: null,
      mywallets: null,
      setting: null,
      isLoading: false,
      showMusicModal: false, // Controle do modal de música
    };
  },
   mounted() {
        this.getProfile();
        this.modalDeposit = new Modal(document.getElementById('modalElDeposit'), {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
            closable: true,
            onHide: () => {
                this.paymentType = null;
            },
            onShow: () => {},
            onToggle: () => {},
        });
        
    },

  
  
  
      methods: {
        setWallet: function(id) {
            const _this = this;
            const _toast = useToast();
            _this.isLoadingWallet = true;

            HttpApi.post('profile/mywallet/'+ id, {})
                .then(response => {
                   _this.getMyWallet();
                    _this.isLoadingWallet = false;
                    window.location.reload();

                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        // _toast.error(`${value}`);
                    });
                    _this.isLoadingWallet = false;
                });
        },
          
            toggleMusicModal() {
      this.showMusicModal = !this.showMusicModal; // Alterna o estado do modal de música
    },
        getWallet: function() {
            const _this = this;
            const _toast = useToast();
            _this.isLoadingWallet = true;

            HttpApi.get('profile/wallet')
                .then(response => {
                    _this.wallet = response.data.wallet;
                    _this.isLoadingWallet = false;
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        // _toast.error(`${value}`);
                    });
                    _this.isLoadingWallet = false;
                });
        },
        getMyWallet: function() {
            const _this = this;
            const _toast = useToast();

            HttpApi.get('profile/mywallet')
                .then(response => {
                    _this.mywallets = response.data.wallets;
                })
                .catch(error => {
                    Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
                        // _toast.error(`${value}`);
                    });
                });
        },
        getSetting: function() {
            const _this = this;
            const settingStore = useSettingStore();
            const settingData = settingStore.setting;

            if(settingData) {
                _this.setting = settingData;
            }

            _this.isLoading = false;
        },
        rolloverPercentage(balance) {
            return Math.max(0, ((balance / 100) * 100).toFixed(2));
        },
        toggleModalDeposit() {
            this.modalDeposit.toggle();
        },
        
        async getProfile() {
            this.isLoadingProfile = true;
            try {
                const response = await HttpApi.get("/profile/");
                const user = response.data.user;
                // console.log(user)
                if (user) {
                    this.idUser = user.id;
                    this.userName = user.name;
                }
            } catch (error) {
                console.error("Erro ao obter perfil:", error);
            } finally {
                this.isLoadingProfile = false;
            }
        },
        downloadApp() {
            const link = document.createElement('a');
            link.href = '/assets/1716362201126-rox777_1.0.2.apk';
            link.download = 'rox777_1.0.2.apk';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async logoutAccount() {
            const authStore = useAuthStore();
            const _toast = useToast();
            try {
                await HttpApi.post("auth/logout", {});
                authStore.logout();
                this.$router.push("/home/game");
                // _toast.success("Você foi desconectado com sucesso");
            } catch (error) {
                console.error("Erro ao fazer logout:", error);
                const errorMsg = JSON.parse(error.request.responseText);
                _toast.error(errorMsg.message || "Erro ao fazer logout");
            }
        }
    },
    created() {
        this.getWallet();
        this.getMyWallet();
        this.getSetting();
    },
    watch: {

    }
};
</script>

<style scoped>
.roxDepositButton {
  background-color: var(--ci-primary-opacity-color);
  padding: 0.7rem;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .roxDepositButton {
    padding: 0.4rem 0 0.4rem 0.4rem;
    border-radius: 8px 0 0 8px;
    border-right: 1px solid var(--ci-primary-opacity-color);
    transform: scale(0.8);
  }
}
</style>
