import "./css/app.css"; // Importa o CSS
import "./js/app.js"; // Importa o JS
import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from './Router';
import httpApi from "@/Services/HttpApi";
import { createI18n } from "vue-i18n";
import VueAxios from 'vue-axios';
import VueFullscreen from 'vue-fullscreen';
import Toast from "vue-toastification";
import { vMaska } from 'maska';

axios.defaults.baseURL = (window.location.protocol+ "//" + window.location.host);





function applyDynamicStyles(styles) {
  const root = document.documentElement;

  // Adicionar uma classe específica para aplicar os estilos
  root.classList.add('dynamic-styles');

  // Aplicar variáveis CSS com a classe dinâmica
  let cssVariables = '';
  for (const key in styles) {
      if (key.includes('_color') || key === 'font_family_default' || key === 'border_radius') {
          let cssKey = `--${key.replace(/_/g, '-')}`;
          cssVariables += `${cssKey}: ${styles[key]} !important;`;
      }
  }

  // Garantir que --ci-primary-color receba o valor de primary_color
  if (styles.primary_color) {
      cssVariables += `--ci-primary-color: ${styles.primary_color} !important;`;
      cssVariables += `--ci-secundary-color: ${styles.secundary_color} !important;`;
      cssVariables += `--ci-primary-opacity-color: ${styles.primary_opacity_color} !important;`;
      cssVariables += `--text-color: ${styles.text_color} !important;`;
      cssVariables += `--title-color: ${styles.title_color} !important;`;
      cssVariables += `--background-color: ${styles.background_color} !important;`;
      cssVariables += `--background-base: ${styles.background_base} !important;`;
      cssVariables += `--sidebar-color: ${styles.sidebar_color} !important;`;
      cssVariables += `--sidebar-color-dark: ${styles.sidebar_color} !important;`;
      cssVariables += `--navtop-color: ${styles.navtop_color} !important;`;
      cssVariables += `--navtop-color-dark: ${styles.navtop_color} !important;`;
      cssVariables += `--footer-color: ${styles.footer_color} !important;`;
      cssVariables += `--footer-color-dark: ${styles.footer_color} !important;`;
      cssVariables += `--card-color-dark: ${styles.card_color} !important;`;
      cssVariables += `--card-color: ${styles.card_color} !important;`;
      cssVariables += `--side-menu-color: ${styles.side_menu} !important;`;
  }

  const styleElement = document.createElement("style");
  styleElement.type = "text/css";
  styleElement.innerHTML = `:root { ${cssVariables} }`;
  styleElement.innerHTML += `.dark :root { ${cssVariables} }`;

  // Adicionar o custom_css
  if (styles.custom_css) {
      styleElement.innerHTML += `.dynamic-styles { ${styles.custom_css.replace(/([^;\s}]+:[^;}]+)(;|})/g, "$1 !important$2")} }`;
  }

  document.head.appendChild(styleElement);
}


// Função para buscar estilos dinâmicos da API
async function fetchDynamicStyles() {
    try {
        const response = await axios.get("/api/settings/layout");
        if (response?.data) {
            applyDynamicStyles(response.data);
        } else {
            console.warn("Nenhum estilo encontrado na resposta da API:", response.data);
        }
    } catch (e) {
        console.error("Erro ao buscar estilos dinâmicos:", e);
    }
}


// Carregar as traduções dinamicamente
async function loadMessages() {
  const en = await import('@/lang/en.json');
  const pt = await import('@/lang/pt_BR.json');
  const es = await import('@/lang/es.json');
  
  return {
    en,
    pt,
    es
  };
}




(async () => {
  await fetchDynamicStyles();
  const messages = await loadMessages();

  const i18n = createI18n({
    locale: "en", // Idioma padrão
    fallbackLocale: "pt",
    messages
  });

  const app = createApp(App);

  // Configurações globais
  app.config.globalProperties.state = {
    platformName() {
      return 'DAANROX';
    },
    dateFormatServer(date) {
      const currentDate = new Date(date);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`; // Remove hífens do final
    },
    formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const dayAfterTomorrow = new Date(today);
      dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
      const options = { hour: '2-digit', minute: '2-digit' };
      if (date.toDateString() === today.toDateString()) {
        return `Hoje às ${date.toLocaleTimeString(document.documentElement.getAttribute("lang"), options)}`;
      } else if (date.toDateString() === tomorrow.toDateString()) {
        return `Amanhã às ${date.toLocaleTimeString(document.documentElement.getAttribute("lang"), options)}`;
      } else if (date.toDateString() === dayAfterTomorrow.toDateString()) {
        const dayOfWeek = new Intl.DateTimeFormat(document.documentElement.getAttribute("lang"), { weekday: 'long' }).format(date);
        return `Na ${dayOfWeek} às ${date.toLocaleTimeString(document.documentElement.getAttribute("lang"), options)}`;
      } else {
        return `${date.toLocaleDateString(document.documentElement.getAttribute("lang"))} às ${date.toLocaleTimeString(document.documentElement.getAttribute("lang"), options)}`;
      }
    },
    generateSlug(text) {
      return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-') // Substitui espaços por hífen
        .replace(/[^\w\-]+/g, '') // Remove caracteres especiais
        .replace(/\-\-+/g, '-') // Substitui múltiplos hífens por um único hífen
        .replace(/^-+/, '') // Remove hífens do início
        .replace(/-+$/, ''); // Remove hífens do final
    },
    timeAgo(value) {
      return moment(value).fromNow();
    },
    currencyFormat(value, currency) {
      if (value === undefined || currency === undefined) {
        currency = 'USD';
      }
  
      const options = {
        style: 'currency',
        currency: currency
      };
  
      return value.toLocaleString(document.documentElement.getAttribute("lang"), options);
    },
    currencyUSD(value) {
      if (typeof value === 'number') {
        return new Intl.NumberFormat('en-US', {
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(value);
      } else {
        return new Intl.NumberFormat('en-US', {
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(parseFloat(value));
      }
    },
    limitCharacters(value, limite) {
      if (!value) return '';
      if (value.length <= limite) return value;
      return value.slice(0, limite) + '...';
    },
  };

  // Usando Pinia para gerenciamento de estado
  app.use(createPinia());

  // Usando Vue Router
  app.use(router);

  // Usando Vue I18n
  app.use(i18n);

  // Usando Axios
  app.config.globalProperties.$http = httpApi;

  // Outros plugins (Toast, VueFullscreen, etc.)
  app.use(VueAxios, httpApi);
  app.use(VueFullscreen);

  const optionsToast = {};
  app.use(Toast, optionsToast);

  // Diretivas e configurações adicionais
  app.directive("maska", vMaska);


  
  // Busca e aplica os estilos dinâmicos antes de montar o app
  await fetchDynamicStyles();

  // Carregamento de Configurações e Autenticação
  const { useSettingStore } = await import("@/Stores/SettingStore.js");
  const setting = useSettingStore();
  try {
    const settingData = await setting.getSetting();
    setting.setSetting(settingData);
  } catch (e) {
    console.error(e);
  }

  if (localStorage.getItem('token')) {
    const { useAuthStore } = await import("@/Stores/Auth.js");
    const auth = useAuthStore();
    try {
      auth.setIsAuth(true);
      const user = await auth.checkToken();
      if (user !== undefined) {
        auth.setUser(user);
      }
    } catch (e) {
      auth.setIsAuth(false);
    }
  }

  app.mount('#app');
})();
