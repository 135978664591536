
<template>
    <!-- barra no topo-->
    <div class="bar-sport-nav shadow bg-gray-200 dark:bg-gray-700">
        <div class="flex w-full justify-between">
            <div class="mx-auto flex w-full">
                <RouterLink :to="{ name: 'sports' }" class="mr-3" active-class="top-menu-active">
                    <svg data-cy="ic-home" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="fill: currentcolor; color: inherit; width: auto; height: 32px;">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.669 8.94762C16.2887 8.60535 15.7113 8.60535 15.331 8.94762L7.33104 16.1476C7.12032 16.3373 7 16.6074 7 16.8909V23.0002C7 23.5525 7.44772 24.0002 8 24.0002H11.2727C11.825 24.0002 12.2727 23.5525 12.2727 23.0002V21.6002C12.2727 19.9433 13.6159 18.6002 15.2727 18.6002H16.7273C18.3841 18.6002 19.7273 19.9433 19.7273 21.6002V23.0002C19.7273 23.5525 20.175 24.0002 20.7273 24.0002H24C24.5523 24.0002 25 23.5525 25 23.0002V16.8909C25 16.6074 24.8797 16.3373 24.669 16.1476L16.669 8.94762ZM13.9931 7.46104C15.134 6.43421 16.866 6.43421 18.0069 7.46104L26.0069 14.661C26.639 15.23 27 16.0405 27 16.8909V23.0002C27 24.657 25.6569 26.0002 24 26.0002H20.7273C19.0704 26.0002 17.7273 24.657 17.7273 23.0002V21.6002C17.7273 21.0479 17.2796 20.6002 16.7273 20.6002H15.2727C14.7204 20.6002 14.2727 21.0479 14.2727 21.6002V23.0002C14.2727 24.657 12.9296 26.0002 11.2727 26.0002H8C6.34315 26.0002 5 24.657 5 23.0002V16.8909C5 16.0405 5.36096 15.23 5.99311 14.661L13.9931 7.46104Z"></path>
                    </svg>
                </RouterLink>

                <RouterLink :to="{ name: 'sportLivePage' }" active-class="top-menu-active" class="mr-3">
                    <svg data-cy="ic-live" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="fill: currentcolor; color: inherit; width: auto; height: 32px;">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6842 14.2441C22.2145 13.6816 22.4796 12.8599 22.4796 11.7788C22.4796 10.6948 22.216 9.87305 21.6886 9.31348C21.1613 8.75391 20.3864 8.47412 19.3639 8.47412C18.3444 8.47412 17.5666 8.75684 17.0304 9.32227C16.4972 9.88477 16.2306 10.7007 16.2306 11.77C16.2306 12.8511 16.4987 13.6743 17.0348 14.2397C17.5739 14.8052 18.3473 15.0879 19.3551 15.0879C20.3776 15.0879 21.154 14.8066 21.6842 14.2441ZM19.3551 13.6421C18.4909 13.6421 18.0587 13.021 18.0587 11.7788C18.0587 10.5249 18.4938 9.89795 19.3639 9.89795C19.7917 9.89795 20.1125 10.0518 20.3263 10.3594C20.5431 10.6641 20.6515 11.1372 20.6515 11.7788C20.6515 12.4175 20.5446 12.8877 20.3307 13.1895C20.1198 13.4912 19.7946 13.6421 19.3551 13.6421Z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3744 13.7959L13.6908 15H15.5892L13.4666 8.54883H11.1507L9.05453 15H10.9618L11.2869 13.7959H13.3744ZM12.7371 11.3174L13.014 12.3721H11.6473C11.9139 11.3643 12.0853 10.689 12.1615 10.3462C12.2406 10.0034 12.2933 9.75732 12.3197 9.60791C12.349 9.76904 12.409 10.0356 12.4998 10.4077C12.5936 10.7798 12.6727 11.083 12.7371 11.3174Z"></path>
                        <path d="M8.62843 17.5752H10.5708L8.46584 24H6.42238L4.32619 17.5752H6.27736L7.15187 20.8315C7.33351 21.5552 7.43312 22.0591 7.4507 22.3433C7.47121 22.1382 7.51222 21.8818 7.57375 21.5742C7.63527 21.2666 7.68947 21.0249 7.73634 20.8491L8.62843 17.5752Z"></path>
                        <path d="M11.4495 17.5752V24H13.1942V17.5752H11.4495Z"></path>
                        <path d="M18.3752 17.5752H20.3175L18.2126 24H16.1691L14.0729 17.5752H16.0241L16.8986 20.8315C17.0802 21.5552 17.1798 22.0591 17.1974 22.3433C17.2179 22.1382 17.2589 21.8818 17.3205 21.5742C17.382 21.2666 17.4362 21.0249 17.4831 20.8491L18.3752 17.5752Z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4126 23.2441C26.9428 22.6816 27.208 21.8599 27.208 20.7788C27.208 19.6948 26.9443 18.873 26.417 18.3135C25.8896 17.7539 25.1147 17.4741 24.0923 17.4741C23.0727 17.4741 22.2949 17.7568 21.7588 18.3223C21.2256 18.8848 20.959 19.7007 20.959 20.77C20.959 21.8511 21.227 22.6743 21.7632 23.2397C22.3022 23.8052 23.0757 24.0879 24.0835 24.0879C25.1059 24.0879 25.8823 23.8066 26.4126 23.2441ZM24.0835 22.6421C23.2192 22.6421 22.7871 22.021 22.7871 20.7788C22.7871 19.5249 23.2221 18.8979 24.0923 18.8979C24.52 18.8979 24.8408 19.0518 25.0547 19.3594C25.2715 19.6641 25.3799 20.1372 25.3799 20.7788C25.3799 21.4175 25.2729 21.8877 25.0591 22.1895C24.8481 22.4912 24.5229 22.6421 24.0835 22.6421Z"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 5.79086 2.79086 4 5 4H27C29.2091 4 31 5.79086 31 8V24C31 26.2091 29.2091 28 27 28H5C2.79086 28 1 26.2091 1 24V8ZM5 6H27C28.1046 6 29 6.89543 29 8V24C29 25.1046 28.1046 26 27 26H5C3.89543 26 3 25.1046 3 24V8C3 6.89543 3.89543 6 5 6Z"></path>
                    </svg>
                </RouterLink>

                <RouterLink :to="{ name: 'sportsFavorites' }" class="mr-3" active-class="top-menu-active">
                    <svg data-cy="ic-favourite" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="fill: currentcolor; color: inherit; width: auto; height: 32px;">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6254 5.94061C15.0996 4.68646 16.9005 4.68646 17.3746 5.94061L19.6994 12.0896L26.5777 12.2991C27.8616 12.3383 28.6024 14.0188 27.4139 14.8991L22.0169 18.8962L23.9146 25.138C24.12 25.8138 23.8039 26.4229 23.3534 26.7351C22.9076 27.044 22.2593 27.1206 21.7036 26.7608L16 23.0679L10.2965 26.7608C9.74081 27.1206 9.09247 27.044 8.64671 26.7351C8.19618 26.4229 7.88008 25.8138 8.08553 25.138L9.98319 18.8962L4.58622 14.8991C3.39765 14.0188 4.13844 12.3383 5.42238 12.2991L12.3006 12.0896L14.6254 5.94061ZM16 7.96023L14.0424 13.138C13.8266 13.709 13.2865 14.0605 12.7132 14.078L7.07004 14.2499L11.4899 17.5233C11.9687 17.8779 12.1942 18.4993 12.0136 19.0935L10.434 24.2891L15.1943 21.207C15.6833 20.8903 16.3168 20.8903 16.8058 21.207L21.5661 24.2891L19.9865 19.0935C19.8058 18.4993 20.0314 17.8779 20.5102 17.5233L24.93 14.2499L19.2869 14.078C18.7136 14.0605 18.1735 13.709 17.9577 13.138L16 7.96023ZM26.2235 13.2919L26.223 13.2923L26.2235 13.2919Z"></path>
                    </svg>
                </RouterLink>
            </div>

            <RouterLink :to="{ name: 'sportsSearch' }" class="mr-3" active-class="top-menu-active">
                <svg data-cy="ic-search" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="fill: currentcolor; color: inherit; width: auto; height: 32px;">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99999 14C9.99999 10.134 13.134 7 17 7C20.866 7 24 10.134 24 14C24 17.866 20.866 21 17 21C13.134 21 9.99999 17.866 9.99999 14ZM17 5C12.0294 5 7.99999 9.02944 7.99999 14C7.99999 16.2926 8.85719 18.3849 10.2686 19.9741L5.29289 24.9497C4.90237 25.3403 4.90237 25.9734 5.29289 26.364C5.68342 26.7545 6.31658 26.7545 6.70711 26.364L11.7559 21.3152C13.2325 22.3756 15.0433 23 17 23C21.9706 23 26 18.9706 26 14C26 9.02944 21.9706 5 17 5Z"></path>
                </svg>
            </RouterLink>
        </div>
    </div>
</template>

<script>
    import { RouterLink } from "vue-router";

    export default {
        props: [],
        components: { RouterLink },
        data() {
            return {
                isLoading: false,
            }
        },
        setup(props) {


            return {};
        },
        computed: {

        },
        mounted() {

        },
        methods: {

        },
        watch: {

        },
    };
</script>

<style scoped>

</style>
