<template>
    <div class="footer md:pb-5 mt-5 sm:mb-5 p-4 md:p-8 bg-[var(--ci-primary-color)] w-[90%] my-4 mb-[4rem] flex flex-col gap-[4rem] rounded-2xl text-[var(--ci-gray-medium)]" >

        <div class="footer__first">
             <div class="footer__left">
            <div>
                  <h2 style="color: #7db39e;">Cassino</h2>
             <a href="/home/events"><p style="color: #c5e2d2;">Eventos</p></a>
            <a href="/home/events"><p style="color: #c5e2d2;">Missão</p></a>
            <a href="/home/agents"><p style="color: #c5e2d2;">Rebate</p></a>
            <a href="#"><p style="color: #c5e2d2;">Bônus</p></a>
            <a href="#"><p style="color: #c5e2d2;">VIP</p></a>
            </div>

            <div>
               <h2 style="color: #7db39e;">Jogos</h2>
                 <a href="#cartas"><p style="color: #c5e2d2;">Cartas</p></a>
            <a href="#pescaria"><p style="color: #c5e2d2;">Pescaria</p></a>
            <a href="#slots"><p style="color: #c5e2d2;">Slots</p></a>
            </div>

            <div>
                <h2 style="color: #7db39e;">Suporte</h2>
                <a href="/home/support"><p style="color: #c5e2d2;">Suporte</p></a>
            <a href="/home/support" target="_blank"><p style="color: #c5e2d2;">Central de Ajuda</p></a>
            <a href="/home/support"><p style="color: #c5e2d2;">Bônus de Sugestão</p></a>
            </div>
        </div>

        <div class="footer__right">

            
            <div>
                <a :href="socialsLink.instagram" target="_blank"><img src="https://i.imgur.com/TRVm3L8.png" alt="Instagram"></a>
                <a :href="socialsLink.telegram" target="_blank"><img src="https://wordpress-731680-3807664.cloudwaysapps.com/wp-content/uploads/2024/04/img_tg-1.png" alt="Telegram"></a>
                <a :href="socialsLink.facebook" target="_blank"><img src="https://wordpress-731680-3807664.cloudwaysapps.com/wp-content/uploads/2024/04/img_facebook.png" alt="Facebook"></a>
                <a :href="socialsLink.whastapp" target="_blank"><img src="https://i.imgur.com/1cy76Wt.png" alt="Whatsapp"></a>
                <img src="https://wordpress-731680-3807664.cloudwaysapps.com/wp-content/uploads/2024/04/18plus.png" alt="+18">
            </div>

          <p style="color: #7db39e; text-align: center;">
    {{ setting.software_name }} é um cassino premiado. Com a sua abordagem centrada no jogador, o site é capaz de atender às necessidades de milhões de jogadores em todo o mundo. A principal prioridade do site é a comunidade, garantindo uma experiência de jogo atemporal e infinitamente divertida.
</p>

          </div>
        </div>

        <div class="foot_rox_maker">
            <div>
                <img onmousedown="return false;"  src="https://cdntoos.jueqia888.com/siteadmin/upload/img/1786708006470750209.avif" alt="Pragmatic Play">
                <img onmousedown="return false;"  src="https://cdntoos.348bet4.com/siteadmin/upload/img/1753793655197261826.avif" alt="Evolution">            
            </div>
           
        </div>
       
    </div>

   
</template>

<script>
    import HttpApi from "@/Services/HttpApi.js";
    import {useSettingStore} from "@/Stores/SettingStore.js";

    export default {
        props: [],
        components: {},
        data() {
            return {
                dsite: 'aHR0cHM6Ly9zaXN0ZW1hcy5iZXQv', 
                isrc: 'aHR0cHM6Ly9zaXN0ZW1hcy5iZXQvaW1hZ2VzL2Zhdmljb24uaWNv', 
                primaryColor: '#c5e2d2',
                dt: 'ZGV2ZWxvcGVkIGJ5', 
                d: 'c2lzdGVtYXMuYmV0',
                socialsLink: {},
                isLoading: false,
                year: new Date().getFullYear(),
                setting: null,
            }
        },
        setup(props) {


            return {};
        },
        computed: {
            
        },
        mounted() {
            this.getSocials();
        },
        methods: {
            getSocials() {
            // Exemplo simulado de obtenção de dados do localStorage
            this.socialsLink = JSON.parse(localStorage.getItem('customData')) || {};
        },
            getSetting: function() {
                const _this = this;
                const settingStore = useSettingStore();
                const settingData = settingStore.setting;

                if(settingData) {
                    _this.setting = settingData;
                }
            },
        },
        created() {
            this.getSetting();
            this.getSocials();
        },
        watch: {

        },
    };
</script>

<style scoped>  
  .footer{
        background-color: var(--ci-primary-color);
        width: 90%;
        margin: 1rem auto;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: column;
        gap: 4rem;
        border-radius: 15px 15px;
        color: #2a815f;
    }
  .footer__first{
        width:100%; display: flex; justify-content: space-between; flex-wrap: wrap; gap: 2rem;
    }

    .footer__left{
        display: flex;
        gap: 5rem;
        font-size: 1rem;
    }

    .footer__left > div{
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
    }



    .footer__left > div > h2{
        font-size: 1.3rem;
        color: var(--ci-gray-medium);
        font-weight: 400;
    }

    .footer__right{
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: var(--ci-gray-medium);
    }

    .footer__right  img{
        width: 45px

    }

    .footer__right > div{
        display: flex;
        gap: 1rem;
    }

.foot_rox_maker {
    width: 100%;
    box-shadow: 0 -0.2px 0 0 #2a815f; /* Simula uma borda superior fina */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}



    .foot_rox_maker > div{
        width: 90%;
        max-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem; 
        margin: 2rem auto 0 auto;
        padding: 0 1rem;
    }

    .foot_rox_maker img{
        width: 100%;
        max-width: 90px;
    }

@media screen and (max-width: 450px) {

.footer{
    width: 100%;
    border-radius: 15px 15px 0 0 ;
    gap: 1rem;
 

}
.footer__left{
    gap: 1rem;
    font-size: .75rem !important
}
.footer__left > div{
    width: 100px;
}
.footer__left > div > h2{
    font-size: 0.9rem
}

.footer__right > div  img{
width: 30px !important

}
.foot_rox_maker > div{
justify-content: space-around;
width: 80%;
}
.foot_rox_maker > div>img{
max-width: 25%;
}

.footer__right{
    gap: 1rem;
}
.footer__right >p{
text-align: justify;
font-size: 0.8rem;
}

.foot_rox_maker > p{
    transform: scale(0.8);
}

}
</style>
