<template>
    <div v-if="musicSelector" class="fixed left-[50%] top-[50%] bg-[var(--ci-primary-color)] px-4 py-3 z-50 rounded-lg w-[90%] max-w-2xl transform -translate-x-1/2 -translate-y-1/2" style="z-index: 9999;">
        <!-- Seu código anterior permanece aqui -->

        <!-- Elemento de áudio -->
        <audio ref="audioPlayer" :src="audioSource"></audio>
    <div v-if="musicSelector" class="fixed left-[50%] top-[50%] bg-[var(--ci-primary-color)] px-4 py-3 z-50 rounded-lg w-[90%] max-w-2xl transform -translate-x-1/2 -translate-y-1/2" style="z-index: 9999;">
        <div class="w-full flex items-center justify-between mb-3">
            <div></div>
            <div class="text-lg">Música</div>
            <i class="fa-regular fa-xmark cursor-pointer" @click="closeMusicModal"></i>
        </div>

        <div class="w-full px-5 py-3 bg-[var(--side-menu-color)] flex items-center justify-around rounded-lg gap-3 mb-3">
            <p>Música</p>
            <input 
            type="range" 
            v-model="rangeValue" 
            class="styled-range w-[50%]" 
            @input="updateRange" 
            min="0" 
            max="100"
            ref="rangeInput"
            >
            <i class="fa-solid fa-volume-high text-[var(--sub-text-color)]"></i>
        </div>

         <div class="w-full px-4 py-3 bg-[var(--side-menu-color)] flex flex-col items-center rounded-lg gap-2 mb-3">
            <p class="w-full text-center text-white">{{ musicName }}</p>
           
            <div class="w-full flex gap-4 items-center justify-between">
                <div class="cursor-pointer flex items-center justify-center text-[var(--sub-text-color)]">
                    <p class="text-[8px] text-center"><i class="fa-solid fa-repeat  cursor-pointer text-sm text-[var(--sub-text-color)]" ></i><br>Ciclo</p>
                </div>
                
                <div class="w-12 h-12 flex items-center justify-center rounded-full bg-[var(--ci-primary-color)] text-lg text-[var(--sub-text-color)] cursor-pointer shadow-md" @click="prevMusic" ><i class="fa-solid fa-backward-step text-sm" style="cursor: pointer;"></i></div>
                <div class="w-14 h-14 flex items-center justify-center rounded-full bg-[var(--ci-primary-color)] text-xl text-[var(--sub-text-color)] cursor-pointer shadow-md" @click="toggleAudio"><i class="fa-solid" :class="playIconClass"  style="cursor: pointer;"></i></div>
                <div class="w-12 h-12 flex items-center justify-center rounded-full bg-[var(--ci-primary-color)] text-lg text-[var(--sub-text-color)] cursor-pointer shadow-md" @click="nextMusic" ><i class="fa-solid fa-forward-step text-sm" style="cursor: pointer;"></i></div>
                <div class="flex items-center justify-center text-[var(--sub-text-color)]">
                    <p class="text-xss text-center">3<br><span class="text-[8px]">Baixado</span></p>
                </div>
            </div>
        </div>

        <div class="w-full px-4 py-3 bg-[var(--side-menu-color)] flex flex-col items-center rounded-lg gap-2 mb-2">
            <div class="w-full flex justify-around items-center border-gray-300 text-sm mb-2">
                <p class="text-[var(--sub-text-color)] border-b-2 border-[var(--sub-text-color)]">Sistema de música</p>
                <p class="cursor-not-allowed">Minhas músicas</p>
            </div>

            <div class="w-full flex flex-col gap-2 h-[200px]">
            <div class="w-full flex justify-between items-center  border-[var(--ci-secundary-color)] text-sm py-2 px-3">
                <div class="flex gap-2">
                    <i v-if="musicName == musicName1" class="text-xss fa-solid fa-music text-[var(--sub-text-color)]"></i>
                    <p v-if="musicName != musicName1">1</p>
                    <p :class="musicName == musicName1 ? 'text-[var(--sub-text-color)]' : 'text-white'">{{ musicName1 }}</p>
                    <p class="text-gray-500">163K</p>
                </div>

                <i class="fa-solid fa-star text-[var(--sub-text-color)] cursor-pointer"></i>
            </div>

            <div class="w-full flex justify-between items-center  border-[var(--ci-secundary-color)] text-sm py-2 px-3">
                <div class="flex gap-2">
                    <i v-if="musicName == musicName2" class="text-xss fa-solid fa-music text-[var(--sub-text-color)]"></i>
                    <p v-if="musicName != musicName2">2</p>
                    <p :class="musicName == musicName2 ? 'text-[var(--sub-text-color)]' : 'text-white'">{{ musicName2 }}</p>
                    <p class="text-gray-500">358K</p>
                </div>

                <i class="fa-solid fa-star text-[var(--sub-text-color)] cursor-pointer"></i>
            </div>

            <div class="w-full flex justify-between items-center  border-[var(--ci-secundary-color)] text-sm py-2 px-3">
                <div class="flex gap-2">
                    <i v-if="musicName == musicName3" class="text-xss fa-solid fa-music text-[var(--sub-text-color)]"></i>
                    <p v-if="musicName != musicName3">3</p>
                    <p :class="musicName == musicName3 ? 'text-[var(--sub-text-color)]' : 'text-white'">{{ musicName3 }}</p>
                    <p class="text-gray-500">362K</p>
                </div>

                <i class="fa-solid fa-star text-gray-500 cursor-pointer"></i>
            </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['musicSelector'],
    data() {
        return {
            isPlaying: false,
            playIconClass: 'fa-play',
            audioSource: '/storage/rox/musics/1rox.mp3', // Fonte de música
            rangeValue: 30,
            musicName: 'You Spin Me Round',
            musicName1: 'You Spin Me Round',
            musicName2: 'Aposta - Mc Kelvinho',
            musicName3: 'Original',
        };
    },
    methods: {
        closeMusicModal() {
            this.$emit('close');
        },
        updateRange(event) {
            const audio = this.$refs.audioPlayer;
            const volume = event.target.value / 100;
            audio.volume = volume;
            event.target.style.setProperty('--value', event.target.value);
        },
        toggleAudio() {
            const audio = this.$refs.audioPlayer;
            if (audio) {
                if (!this.isPlaying) {
                    audio.play(); // Toca a música
                    this.isPlaying = true;
                    this.playIconClass = 'fa-pause';
                } else {
                    audio.pause(); // Pausa a música
                    this.isPlaying = false;
                    this.playIconClass = 'fa-play';
                }
            }
        },
        prevMusic() {
            const audio = this.$refs.audioPlayer;
            let newSource;
            let newName;

            if (this.audioSource === '/storage/rox/musics/1rox.mp3') {
                newSource = '/storage/rox/musics/3rox.mp3';
                newName = this.musicName3;
            } else if (this.audioSource === '/storage/rox/musics/2rox.mp3') {
                newSource = '/storage/rox/musics/1rox.mp3';
                newName = this.musicName1;
            } else {
                newSource = '/storage/rox/musics/2rox.mp3';
                newName = this.musicName2;
            }

            this.audioSource = newSource;
            this.musicName = newName;

            if (this.isPlaying) {
                this.toggleAudio();
            }

            audio.load(); // Carrega a nova música

            setTimeout(() => {
                this.toggleAudio(); // Reproduz a nova música após carregamento
            }, 1000);
        },
        nextMusic() {
            const audio = this.$refs.audioPlayer;
            let newSource;
            let newName;

            if (this.audioSource === '/storage/rox/musics/1rox.mp3') {
                newSource = '/storage/rox/musics/2rox.mp3';
                newName = this.musicName2;
            } else if (this.audioSource === '/storage/rox/musics/2rox.mp3') {
                newSource = '/storage/rox/musics/3rox.mp3';
                newName = this.musicName3;
            } else {
                newSource = '/storage/rox/musics/1rox.mp3';
                newName = this.musicName1;
            }

            this.audioSource = newSource;
            this.musicName = newName;

            if (this.isPlaying) {
                this.toggleAudio();
            }

            audio.load();

            setTimeout(() => {
                this.toggleAudio();
            }, 1000);
        },
    },
    mounted() {
        const audio = this.$refs.audioPlayer;
        audio.volume = this.rangeValue / 100; // Define o volume inicial
    }
};

</script>

<style scoped>
.cardside {
  background-color: var(--side-menu-color);
  border: 1px solid var(--side-menu-color);
  width: calc(50% - 8px);
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--ci-gray-over);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.cardside:hover {
  background-color: var(--ci-primary-color);
  border: 1px solid var(--ci-gray-medium);
  color: var(--ci-primary-opacity-color);
}

.cardside > img {
  height: 35px;
}

@media screen and (max-width: 480px) {
  .cardside {
    height: 65px;
    gap: 0.3rem;
    padding: 6px;
    font-size: 0.8rem;
    width: calc(50% - 6px);
  }
  .cardside > img {
    height: 25px;
  }
}

.image-container {
  width: calc(50% - 8px);
  position: relative;
}

.full-width {
  width: 97.5%;
}

.image-container img {
  width: 100%;
  cursor: pointer;
}

.image-container p {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  text-align: left;
  padding: 5px;
  margin: 0;
  cursor: pointer;
}

.popup{
    position: absolute;
    z-index: 9999;
    left: 248px;
}

@media screen and (max-width: 480px) {
    .popup{
        left: 115%;
    }
    
}





input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background: transparent;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background: linear-gradient(
        to right, 
        var(--sub-text-color) 0%, 
        var(--sub-text-color) calc(var(--value) * 1%), 
        rgba(255, 255, 255, 0.7) calc(var(--value) * 1%), 
        rgba(255, 255, 255, 0.7) 100%
    );
    border-radius: 6px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background: var(--ci-primary-color);
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px; /* Ajusta a posição vertical do thumb */
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background: linear-gradient(
        to right, 
        var(--sub-text-color) 0%, 
        var(--sub-text-color) calc(var(--value) * 1%), 
        rgba(255, 255, 255, 0.7) calc(var(--value) * 1%), 
        rgba(255, 255, 255, 0.7) 100%
    );
    border-radius: 6px;
}

input[type="range"]::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: var(--ci-primary-color);
    border-radius: 50%;
    cursor: pointer;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: var(--sub-text-color);
    border-radius: 6px;
}

input[type="range"]::-ms-fill-upper {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 6px;
}

input[type="range"]::-ms-thumb {
    width: 18px;
    height: 18px;
    background: var(--ci-primary-color);
    border-radius: 50%;
    cursor: pointer;
}

input[type="range"].styled-range {
    --value: 30;
    background: linear-gradient(
        to right, 
        var(--sub-text-color) 0%, 
        var(--sub-text-color) calc(var(--value) * 1%), 
        rgba(255, 255, 255, 0.7) calc(var(--value) * 1%), 
        rgba(255, 255, 255, 0.7) 100%
    );
}
   
  .flex.justify-around.items-center.px-3.relative i {
    color: #f0c059 !important;
}

  .text-xss {
    font-size: .55rem;
    line-height: 1rem;
}
  
  .w-555 {
    width: 0.85rem;
}

  
</style>
